<template>
  <action-popup
    :active.sync="isActive"
    :basket="isPromo"
    class="header-basket"
    :open-basket="isOpenBasket"
    popup-class="header-basket__popup"
    size="medium"
    @blur.self="blurTest"
  >
    <header-popup-activator slot="activator" :notify="list.length" icon="shopping-cart" />
    <div class="header-basket__content">
      <div class="header-basket__head">
        <div class="medium-title">
          {{ $t('title') }}
        </div>
        <plain-button icon="close" color="dim" size="small" @click="isActive = false" />
      </div>
      <div class="header-basket__body">
        <base-basket
          :list="list"
          :loading="isLoading"
          :sending="isSending"
          editable
          deletable
          @remove="onRemove"
          @edit="onEdit"
        />
      </div>
      <div class="header-basket__foot">
        <div class="header-basket__summary">
          <div class="header-basket__summary-cost medium-title">
            <div class="header-basket__summary-cost-item medium-title">
              {{ `${$t('total')}: ` }}
            </div>
            <div
              v-if="fullCost - totalSum > 0"
              class="header-basket__summary-cost-item discount medium-title error-color"
            >
              {{ ` ${$n(fullCost, 'currency')}` }}
            </div>
            <div class="header-basket__summary-cost-item medium-title">
              {{ ` ${$n(totalSum, 'currency')}` }}
            </div>
          </div>
          <div v-if="totalSum > 0" class="header-basket__summary-promo">
            <plain-button
              v-if="!isPromo"
              color="primary"
              class="header-basket__summary-promo"
              @click="(isPromo = true) && (isActive = true)"
            >
              {{ $t('promo.label') }}
            </plain-button>
            <div v-else class="p-inputgroup" @blur="isActive = true">
              <InputText
                v-model="promoCode"
                type="text"
                class="p-inputtext-sm"
                @input="isActive = true"
                @blur="isActive = true"
              />
              <Button icon="pi pi-check" class="p-button-blue" @click="sendPromo(promoCode)" />
              <Button icon="pi pi-times" class="p-button-danger" @click="closePromo()" />
            </div>
          </div>
        </div>
        <div v-if="list.length" class="header-basket__actions">
          <base-button
            v-if="totalSum === 0"
            size="small"
            class="header-basket__actions-btn"
            @click="activate"
          >
            {{ $t('activate') }}
          </base-button>
          <base-button
            v-if="totalSum > 0"
            size="small"
            class="header-basket__actions-btn"
            @click="pay"
          >
            {{ $t('pay') }}
          </base-button>
          <plain-button
            v-if="list.length"
            color="primary"
            class="header-basket__actions-btn"
            @click="openMore"
          >
            {{ $t('more') }}
          </plain-button>
        </div>
      </div>
    </div>
  </action-popup>
</template>

<script>
import ActionPopup from '@/components/ActionPopup/ActionPopup';
import BaseBasket from '@/components/BaseBasket/BaseBasket.vue';
import BaseBasketHeader from '@/components/BaseBasket/BaseBasketHeader.vue';
import BaseBasketSummary from '@/components/BaseBasket/BaseBasketSummary.vue';
import HeaderPopupActivator from '../HeaderPopupActivator.vue';
import PlainButton from '@/components/Buttons/PlainButton.vue';

import { parseCost } from '@/utils/parsers/costParser';
import Vue from 'vue';
import wizardPay from '@/mixins/billmgr/wizardPay';
// import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'HeaderBasket',
  components: {
    ActionPopup,
    BaseBasket,
    HeaderPopupActivator,
    PlainButton,
    Button,
    InputText,
  },
  mixins: [wizardPay, /*wizard,*/ handleRedirect, updateBalance, billMgrToolActions],
  data() {
    return {
      isActive: false,
      promoCode: '',
      basketModal: null,
      isPromo: false,
      isSending: false,
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleBasket.list;
    },

    isLoading() {
      return this.$store.state.moduleBasket.isLoading;
    },
    isPromoActive() {
      return this.isPromo ? 'open' : 'close';
    },
    model() {
      return this.$store.state.moduleBasket.model;
    },
    fullCost() {
      return parseCost(this.model.fullcost);
    },
    totalSum() {
      return parseCost(this.model.total_sum);
    },
    isOpenBasket() {
      return this.isActive ? 'open' : 'close';
    },
    route() {
      return this.$router.currentRoute.name;
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    typeOrg() {
      return this.$store.state.moduleProfile.payers.type;
    },
  },
  watch: {
    list(val) {
      if (this.basketModal) {
        Vue.set(this.basketModal.props, 'list', val);
        Vue.set(this.basketModal.footer.confirm.props, 'disabled', !val.length);
      }
    },
    isPromo() {},
    isActive(val) {
      if (val === true) {
        // this.payFromBasketFirst(this.list);
      } else this.isPromo = false;
      // console.log('isActive', val);
    },
    isLoading(val) {
      if (this.basketModal) Vue.set(this.basketModal.props, 'loading', val);
    },
    isSending(val) {
      if (this.basketModal) Vue.set(this.basketModal.props, 'sending', val);
    },
    totalSum(val) {
      if (this.basketModal) Vue.set(this.basketModal.footer.top.props, 'total', val);
    },
  },
  created() {
    this.$root.$refs.HeaderBasket = this;
  },
  mounted() {
    // console.log(this.typeOrg);
    if (!this.typeOrg && this.access['customer.profile']) {
      this.$store.dispatch('moduleProfile/fetchPayersInfo').catch(e => this.showError(e));
    }
  },
  methods: {
    blurTest() {
      console.log('blur');
    },
    openMore() {
      this.isActive = false;
      this.$modals.open({
        name: 'BasketMore',
        size: 'big',
        heightLimit: 'calc(100vh - 24rem)',
        component: BaseBasket,
        props: {
          list: this.list,
          loading: this.isLoading,
          sending: this.isSending,
          mode: 'expanded',
          editable: true,
          deletable: true,
        },
        on: {
          remove: item => {
            this.onRemove(item);
          },
          edit: params => {
            this.onEdit(params);
          },
        },
        onOpen: inst => (this.basketModal = inst),
        onClose: () => (this.basketModal = null),
        header: {
          component: BaseBasketHeader,
          props: { size: 'big' },
          separate: 'bottom',
          thin: true,
        },
        footer: {
          top: {
            component: BaseBasketSummary,
            props: { total: this.totalSum, size: 'big', amount: this.fullCost },
            separate: 'top',
          },
          confirm: {
            props: {
              title: this.totalSum === 0 ? this.$t('activate') : this.$t('pay'),
              disabled: !this.list.length,
            },
            on: {
              click: this.totalSum === 0 ? this.activate : this.pay,
            },
          },
          cancel: {
            props: { title: this.$t('close') },
            on: {
              click: this.$modals.close,
            },
          },
        },
      });
    },
    async onRemove(item) {
      let title = `${item.title} #${item.service.id}`;
      if (item.operation === 'prolong') title = this.$t('prolong') + ' ' + title;
      let isSure = await this.askSure(title);
      if (!this.basketModal) this.isActive = true;
      if (isSure) {
        this.isSending = true;
        this.$store
          .dispatch('moduleBasket/removeFromBasket', item)
          .then(() => this.$store.dispatch('moduleBasket/fetchBasket'))
          .finally(() => (this.isSending = false));
      }
    },
    async onEdit(params) {
      if (!this.basketModal) this.isActive = true;
      this.sendBillMgrAction(params).then(() => this.$store.dispatch('moduleBasket/fetchBasket'));
    },
    askSure(title) {
      return new Promise(resolve => {
        this.$modals.open({
          name: 'AskSure',
          title: this.$t('sure.title'),
          text: this.$t('sure.text', { msg: title }),
          onDismiss: () => {
            this.$modals.close({ name: 'AskSure' });
            resolve(false);
          },
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.$modals.close();
                  resolve(true);
                },
              },
            },
            cancel: {
              props: { title: this.$t('sure.cancel') },
              on: {
                click: () => {
                  this.$modals.close();
                  resolve(false);
                },
              },
            },
          },
        });
      });
    },
    async sendOrder(payload = {}) {
      // console.log(payload);
      return this.$store
        .dispatch('moduleStack/moduleStackOrder/sendOrder', payload)
        .catch(e => this.showError(e));
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    activate() {
      this.isActive = false;
      const free = {
        clicked_button: 'free',
        func: 'basket',
        id: this.model.billorder,
      };
      this.sendOrder(free).then(data => {
        if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
          if (localStorage.getItem('promo')) localStorage.removeItem('promo');
          this.showSuccessModal(this.$t('success.free'));
        }
      });
    },
    pay() {
      this.isActive = false;
      this.startParams = {
        billorder: this.model.billorder,
      };
      // this.payFromBasketSecond(this.list);
      // console.log(this.typeOrg);
      // if (this.typeOrg) {
      // console.log('go');
      this.runWizardPay()
        .then(data => {
          if (localStorage.getItem('promo')) localStorage.removeItem('promo');
          this.handleRedirect(data.ok);
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.updateBalance();
        })
        .catch(() => {});
      // } else {
      //   console.log('else');
      //   this.runWizard()
      //     .then(data => {
      //       if (localStorage.getItem('promo')) localStorage.removeItem('promo');
      //       this.handleRedirect(data.ok);
      //       this.$store.dispatch('moduleBasket/fetchBasket');
      //       this.updateBalance();
      //     })
      //     .catch(() => {});
      // }
    },
    async sendPromo(promoCode) {
      this.isActive = true;
      this.$store
        .dispatch('moduleBasket/sendPromo', promoCode)
        .then(() => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.showSuccessModal(this.$t('promo.success'));
        })
        .catch(e => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.isPromo = false;
          this.promoCode = '';
          this.showError(e);
        });
    },
    closePromo() {
      this.isActive = true;
      this.isPromo = false;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Корзина",
    "total": "Итого",
    "more": "Подробнее",
    "promo": {
      "label": "Есть промокод?",
      "success": "Промокод успешно применен.",
      "fail": "Промокод не действиетелен"
    },
    "activate": "Активировать",
    "success": {
      "free": "Запрос на включение выполняется, обычно это занимает до минуты"
    },
    "prolong": "Продление",
    "pay": "Перейти к оплате",
    "close": "Закрыть",
    "sure": {
      "title": "Удаление услуги из корзины",
      "text": "Вы собираетесь удалить из корзины %{msg}. Вы уверены?",
      "confirm": "Да, уверен",
      "cancel": "Отмена"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.header-basket {

  &__content {
    flexy(flex-start, stretch, nowrap, column);
    max-height: 'calc(100vh - %s)' % ($header-height + 10px);
  }

  &__head {
    flex: 0 0 auto;
    flexy(space-between, center);
    padding: 0.75rem 1.25rem 1.25rem;
    border-bottom: 1px solid var(--separator);
  }

  &__foot {
    flex: 0 0 auto;
    border-top: 1px solid var(--separator);
    padding: 1.25rem 1.25rem 0.75rem;

    ^[0]__head + & {
      border-top: 0;
    }
  }

  &__body {
    max-height: 400px;
    overflow-y: auto;
    padding: 1.25rem;
  }

  &__summary {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-cost {
      display: flex;
      flex-direction: row;

      &-item {
      padding-right : 0.5rem;


      }
    }
    &-promo {
      //max-height: 1rem;
      height: 2rem;

    }

    .discount {
      padding-right: 0.5rem;
      text-decoration: line-through;
    }

    &:only-child {
      margin-bottom: 0;

    }
  }

  &__actions {
    flexy(flex-start, center, wrap);
    margin: -0.75rem;

    &-btn {
      margin: 0.75rem;
    }
  }
  & >>> &__popup {
    right: -89px;

    &::before {
      right: 100px;
    }
  }
}
</style>
