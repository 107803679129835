<template>
  <div class="personal">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading" class="personal__loader" />
      <card-block v-else :title="$t('title')" class="personal__block">
        <main-card class="personal__card">
          <avatar-upload
            :value="avatar"
            :size="avatarSettings.maxsize || 0"
            :width="avatarSettings.width"
            :height="avatarSettings.height"
            class="personal__avatar-upload"
            @change="onAvatarChange"
          />
          <div v-if="verifyPhone !== 'Проверить'" class="personal__form">
            <div class="l-flex-1_sm-2-gutter-20">
              <label class="typo__label medium-title l-col">Подтвержденный номер телефона</label>
              <label class="typo__label medium-title l-col"
                >{{ verifyPhone }}
                <plain-button
                  v-tooltip="{
                    content: text,
                    placement: 'top-start',
                    autoHide: false,
                    container: false, //по другому не работает
                  }"
                  icon="help"
                  size="small"
                  color="dim"
                  tabindex="-1"
                  class="base-input__hint"
                />
              </label>
            </div>
          </div>
          <div class="personal__form" @keyup.enter="submit">
            <div class="l-flex-1_sm-2-gutter-20">
              <div v-for="(item, key) in personal" :key="key" class="l-col">
                <base-input
                  :type="item.type"
                  :value="formData[key]"
                  :name="item.name"
                  :readonly="item.readonly || false"
                  :disabled="item.disabled || false"
                  :required="item.required || true"
                  :placeholder="item.placeholder || ''"
                  :mask="
                    item.mask && item.mask === '+9 (999) 999-99-99'
                      ? '+7 (999) 999-99-99'
                      : item.mask || ''
                  "
                  :tooltip="needPhoneValidate && item.type === 'tel' ? $t('sure.tooltip') : null"
                  :maxlength="item.maxlength"
                  @change="onChange(key, $event.target.value)"
                >
                  <template v-if="needPhoneValidate && item.type === 'tel'" #icon>
                    <plain-button icon="error" tabindex="-1" color="del" size="medium" />
                  </template>
                  <template v-if="needPhoneValidate && item.type === 'tel'" #after>
                    <plain-button
                      :tooltip="$t('sure.tooltip')"
                      color="del"
                      @click="sendPhone(formData.phone, code)"
                    >
                      {{ $t('sure.send') }}
                    </plain-button>
                  </template>
                  {{ $t(`form.${key}.label`) }}
                </base-input>
              </div>
              <!--              <div class="l-col">-->
              <!--                <base-select-->
              <!--                  name="role"-->
              <!--                  :options="roles"-->
              <!--                  :value="role"-->
              <!--                  label="v"-->
              <!--                  track-by="k"-->
              <!--                  :show-labels="false"-->
              <!--                  :placeholder="$t('form.role.placeholder')"-->
              <!--                  @select="onSetRole('role', $event.k)"-->
              <!--                >-->
              <!--                  {{ $t('form.role.label') }}-->
              <!--                </base-select>-->
              <!--              </div>-->
              <div v-if="timezones.length" class="l-col">
                <base-select
                  name="timezone"
                  :options="timezones"
                  :value="timezone"
                  label="v"
                  track-by="k"
                  :show-labels="false"
                  :placeholder="$t('form.timezone.placeholder')"
                  @select="onChange('timezone', $event.k)"
                >
                  {{ $t('form.timezone.label') }}
                </base-select>
              </div>
            </div>
          </div>
          <base-button
            v-if="provider === '2' || provider === '3'"
            slot="footerEnd"
            theme="outlined"
            class="tariff-plan__btn"
            :loading="formLoading"
            :to="{ name: 'ReRegistration' }"
          >
            {{ $t('form.btn.reRegistration') }}
          </base-button>
          <base-button
            slot="footerEnd"
            :disabled="isValid"
            class="personal-submit__btn"
            :loading="formLoading"
            @click="submit"
          >
            {{ $t('form.btn.save') }}
          </base-button>
        </main-card>
      </card-block>
    </transition>
  </div>
</template>

<script>
import CardBlock from '../components/CardBlock';
import MainCard from '@/components/MainCard/MainCard.vue';
import AvatarUpload from '@/components/Avatar/AvatarUpload.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseSelect from '@/components/Select/BaseSelect.vue';
import { parseBoolFromString } from '@/utils/parsers/stringParser';
import mixin from '../../mixins/index';
import askUnsavedPageLeave from '@/mixins/askUnsavedPageLeave';
import showErrorModal from '@/mixins/showErrorModal';
import { isEqual } from 'lodash';
import phoneVerification from '@/mixins/phoneVerification';
export default {
  name: 'PersonalSettings',
  components: {
    MainCard,
    AvatarUpload,
    CardBlock,
    BaseInput,
    BaseSelect,
  },
  mixins: [mixin, askUnsavedPageLeave, showErrorModal, phoneVerification],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
      hint: 'Для смены номера, обратитесь в техподдержку',
      phoneSendNumber: '',
      avatarValue: null,
      formLoading: false,
      fieldTypes: {
        realname: 'text',
        phone: 'tel',
        email: 'email',
        role: 'select',
      },
      formData: {
        realname: '',
        phone: '',
        email: '',
        timezone: '',
        role: '',
      },
      initialDataBackup: null,
    };
  },
  computed: {
    provider() {
      return this.$store.state.moduleProviders.current;
    },
    fields() {
      return this.profile.fields;
    },
    isValid() {
      return isEqual(this.formData, this.initialDataBackup);
    },
    onlyRoleChanged() {
      const backup = Object.assign({}, this.initialDataBackup);
      const data = Object.assign({}, this.formData);
      delete backup.role;
      delete data.role;
      return isEqual(backup, data);
    },
    verifyPhone() {
      return this.$store.getters['moduleStart/phone'];
      // return this.$store.getters['moduleProfile/phone'];
    },
    text() {
      // console.log(window.location.href.split('#')[0] + '#/support/tickets');
      return `Для смены номера, обратитесь в <strong><a href="${
        window.location.href.split('#')[0]
      }#/support/tickets">техподдержку</a></strong>`;
    },

    avatar() {
      return this.$store.getters['moduleProfile/avatar'];
    },
    avatarSettings() {
      const def = { maxsize: 800 * 1024, width: 80, height: 80, name: 'avatar_file_upload' };
      return this.profile.avatarSettings || def;
    },
    timezones() {
      if (!this.profile || !this.profile.timezones) return [];
      return this.profile.timezones;
    },
    roles() {
      if (!this.profile || !this.profile.roles) return [];
      return this.profile.roles;
    },
    // role() {
    //   if (!this.profile || !this.profile.fields) return {};
    //   const fields = this.profile.fields;
    //   return this.getField(fields.role);
    //   return {
    //   role: this.getField(fields.role),
    // };
    // },
    role() {
      if (!this.profile || !this.roles.length) return '';
      return this.roles.find(i => i.k === this.formData.role);
    },
    timezone() {
      if (!this.profile || !this.timezones.length) return '';
      return this.timezones.find(i => i.k === this.formData.timezone);
    },
    personal() {
      if (!this.profile || !this.profile.fields) return {};
      const fields = this.profile.fields;
      return {
        realname: this.getField(fields.realname),
        phone: this.getField(fields.phone),
        email: this.getField(fields.email),
      };
    },
    hasPersonal() {
      return this.personal && this.personal.realname && this.personal.realname.name;
    },
  },
  watch: {
    hasPersonal: function (val) {
      if (val) this.setInitialValues();
    },
  },
  mounted() {
    if (this.hasPersonal) this.setInitialValues();
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.avatarValue ||
      (this.initialDataBackup && !isEqual(this.formData, this.initialDataBackup))
    ) {
      this.askUnsavedPageLeave()
        .then(() => next())
        .catch(() => {})
        .finally(() => this.$modals.close());
    } else next();
  },
  methods: {
    onAvatarChange(value) {
      this.avatarValue = value || null;
    },
    getBoolValue(str) {
      return str ? parseBoolFromString(str) : false;
    },
    getField(field) {
      return field
        ? {
            ...field,
            type: this.fieldTypes[field.name],
            required: this.getBoolValue(field.required),
            readonly: this.getBoolValue(field.readonly),
            disabled: this.getBoolValue(field.disabled),
          }
        : {};
    },
    setInitialValues() {
      this.formData.realname = this.personal.realname.value;
      this.formData.phone = this.personal.phone.value;
      this.formData.email = this.personal.email.value;
      this.formData.role = this.profile.role;
      this.formData.timezone = this.profile.timezone;
      this.initialDataBackup = { ...this.formData };
      this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.info' });
      this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.services' });
      // this.$store.dispatch('moduleStart/fetchDasboardInfo');
    },
    onChange(key, value) {
      if (key === 'phone') {
        this.formData[key] = value.replace('+', '+7');
      } else this.formData[key] = value;
    },
    onSetRole(key, value) {
      this.formData[key] = value;
      this.setUserRole({ role: this.formData.role })
        .then(() => (this.initialDataBackup = { ...this.formData }))
        .catch(e => this.showError(e))
        .finally(() =>
          setTimeout(() => {
            this.formLoading = false;
          }, 500)
        );
    },
    submit() {
      this.formLoading = true;
      const promises = [];
      if (!isEqual(this.formData, this.initialDataBackup)) {
        const data = this.getPreparedFormData(this.formData);
        delete data.email;
        data.clicked_button = 'ok';
        delete data.role;
        // console.log(data, this.formData);
        // console.log(this.onlyRoleChanged);
        // promises.push(this.updateProfileInfo(data, 'mediator'));
        // if (this.formData.role !== this.initialDataBackup.role)
        //   promises.push(this.setUserRole({ role: this.formData.role }));
        promises.push(this.updateProfileInfo(data, 'usrparam'));
      }
      if (this.avatarValue) {
        promises.push(this.updateProfileInfo({ [this.avatarSettings.name]: this.avatarValue }));
      }
      Promise.all(promises)
        .then(() => (this.initialDataBackup = { ...this.formData }))
        .catch(e => this.showError(e))
        .finally(() =>
          setTimeout(() => {
            this.formLoading = false;
          }, 500)
        );
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Настройки пользователя",
      "sure": {
        "tooltip": "Внимание! Телефон не подтвержден.",
        "send": "Подтвердить",
        "confirm": "Подтвердить",
        "text": "Код отправлен",
        "repeat": "Новый код",
        "success": "Вы успешно подтвердили номер телефона"
        },
      "form": {
        "realname": {
          "label": "Имя"
        },
        "phone": {
          "label": "Телефон (только мобильные номера РФ)"
        },
        "email": {
          "label": "Email"
        },
        "role": {
          "label": "Роль",
          "placeholder": "Выберите роль пользователя"
        },
        "timezone": {
          "label": "Часовой пояс",
          "placeholder": "Начните вводить"
        },
        "btn": {
          "save": "Сохранить",
          "reRegistration": "Переоформление"
        }
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}
.personal {
  display: flex;
  flex-direction: column;
  &-submit {
    margin-top: 52px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &__btn {
      width: 122px;
    }
  }

  &__loader {
    margin-top: 2.5rem;
  }

  &__card {
    max-width: 708px;
  }

  &__form {
    margin: 1.5rem 0 -1.25rem;

    +breakpoint(sm-and-up) {
      margin: 2.5rem 0 0;
    }
  }
}

.user-data {
  &-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  &-block {
    margin-top: 20px;
    & input {
      margin-top: 10px;
      width: 320px;
    }
  }
}
</style>
