import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import PaymethodChange from '@/layouts/All/component/PaymethodChange';
export default {
  mixins: [getLocalizedPeriod],
  data() {
    return {
      payments: '',
      bufferPayments: '',
      ap_actions: {
        on: this.ap_edit,
        off: this.ap_off,
        update: this.ap_edit,
      },
      ap_modal: null,
      ap_tariff: null,
      paymethod: '',
      updatePaymethod: false,
      ap_list: [],
    };
  },
  computed: {
    ap_currentValue() {
      return this.ap_list.find(i => i.k === this.paymethod) || null;
    },
    storedMethod() {
      return this.ap_tariff ? this.ap_tariff.stored_method : null;
    },
  },
  methods: {
    paymethodChange(tariff) {
      // this.loading = true;
      this.$modals.open({
        name: 'PaymethodChange',
        title: this.$t('prolong.payment'),
        component: PaymethodChange,
        size: 'big',
        props: {
          tariff,
          title: this.$t('prolong.payment'),
        },
        on: {
          change: value => {
            // console.log('23232323');
            this.paymethod = value.k;
          },
          payments: value => {
            // this.payments = value;
            this.bufferPayments = value;
            // console.log('---payments---', value);
            // this.$emit('paymethod', value);
          },
        },
        onOpen: inst => {
          // console.log('onOpen');
          this.ap_modal = inst;
        },
        onDismiss: () => {
          this.$emit('paymethod', this.item.stored_method);
          // this.payments = this.item.stored_method;
          // console.log('dismiss', tariff.stored_method, this.item.stored_method);
          this.loading = false;
          this.ap_modal = null;
          this.ap_reset();
          // console.log('dismiss');
        },
        onClose: () => {
          // console.log('close');
          // this.payments = this.item.stored_method;
          // console.log('wewewewe', tariff.stored_method, this.item.stored_method);
          // this.$emit('paymethod', tariff.stored_method);
          // this.loading = false;
          this.ap_modal = null;
          this.ap_reset();
        },
        footer: {
          confirm: {
            props: { title: this.$t('prolong.change') },
            on: {
              click: () => {
                this.ap_sendEdit(tariff, this.bufferPayments);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                // console.log('cancel', tariff.stored_method, this.item.stored_method);
                // this.$emit('paymethod', tariff.stored_method);
                this.loading = false;

                this.$modals.close();
              },
            },
          },
        },
      });
    },
    async ap_sendEdit(tariff, value) {
      const params = {
        elid: tariff.id,
        func: `${tariff.itemtype}.edit`,
        stored_method: value,
      };
      let modal = null;
      this.$modals.open({
        name: 'AutoprolongResult',
        // title: this.$t('sending'),
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      let result = '';
      await this.$store
        .dispatch('moduleAll/updateDetail', params)
        .then(async data => {
          result = data && data.ok ? 'success' : 'error';
          if (result === 'success') {
            this.loading = true;
            if (tariff.itemtype) {
              await this.$store
                .dispatch('moduleAll/fetchItem', {
                  id: tariff.id,
                  item: tariff.itemtype,
                  silent: true,
                  // state: !value ? 'off' : '',
                })
                .then(async data => {
                  if (data && data.stored_method) {
                    this.ap_tariff = data;

                    this.$emit('event', { id: data.id, itemtype: tariff.itemtype });
                    // console.log('emit');
                    await this.$store
                      .dispatch('moduleAll/paymethod', { id: data.id, itemtype: tariff.itemtype })
                      .then(() => {
                        this.payments = this.bufferPayments;
                        this.loading = false;
                        this.bufferPayments = '';
                      });
                  }
                });
            } else {
              // console.log('else');
              this.ap_reset();
            }
            // console.log('close');
            this.$modals.close({ name: this.ap_modal.name });
          }
        })
        .catch(e => {
          result = 'error';
        })
        .finally(() => {
          Vue.set(modal, 'title', this.$t('prolong.result.title'));
          Vue.set(
            modal,
            'text',
            this.$t('prolong.result.desc', {
              id: this.ap_tariff.stored_method,
              // this.ap_tariff.stored_method === '0'
              //   ? 'Лицевой счет'
              //   : this.ap_tariff.stored_method === ''
              //   ? ''
              //   : this.ap_tariff.stored_method,
              // : 'Банковская карта',
            })
          );
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'closable', true);
          Vue.set(modal, 'footer', {
            confirm: {
              props: { title: this.$t('prolong.result.ok') },
              on: {
                click: () => {
                  // console.log(value, '-----value----');
                  this.$store
                    .dispatch('moduleAll/setAction', { state: value === 'null' ? 'off' : 'clear' })
                    .then(() => this.$modals.close());
                  // this.$modals.close();
                  // console.log('clear state, update flag');
                },
              },
            },
          });
          this.ap_reset();
        });
    },
    ap_reset() {
      // console.log('reset');
      this.paymethod = '';
      this.ap_list = [];
      this.bufferPayments = '';
      // this.ap_tariff = null;
    },
  },
};
