var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading)?_c('base-loader',{staticClass:"personal__loader"}):_c('card-block',{staticClass:"personal__block",attrs:{"title":_vm.$t('title')}},[_c('main-card',{staticClass:"personal__card"},[_c('avatar-upload',{staticClass:"personal__avatar-upload",attrs:{"value":_vm.avatar,"size":_vm.avatarSettings.maxsize || 0,"width":_vm.avatarSettings.width,"height":_vm.avatarSettings.height},on:{"change":_vm.onAvatarChange}}),(_vm.verifyPhone !== 'Проверить')?_c('div',{staticClass:"personal__form"},[_c('div',{staticClass:"l-flex-1_sm-2-gutter-20"},[_c('label',{staticClass:"typo__label medium-title l-col"},[_vm._v("Подтвержденный номер телефона")]),_c('label',{staticClass:"typo__label medium-title l-col"},[_vm._v(_vm._s(_vm.verifyPhone)+" "),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.text,
                  placement: 'top-start',
                  autoHide: false,
                  container: false, //по другому не работает
                }),expression:"{\n                  content: text,\n                  placement: 'top-start',\n                  autoHide: false,\n                  container: false, //по другому не работает\n                }"}],staticClass:"base-input__hint",attrs:{"icon":"help","size":"small","color":"dim","tabindex":"-1"}})],1)])]):_vm._e(),_c('div',{staticClass:"personal__form",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"l-flex-1_sm-2-gutter-20"},[_vm._l((_vm.personal),function(item,key){return _c('div',{key:key,staticClass:"l-col"},[_c('base-input',{attrs:{"type":item.type,"value":_vm.formData[key],"name":item.name,"readonly":item.readonly || false,"disabled":item.disabled || false,"required":item.required || true,"placeholder":item.placeholder || '',"mask":item.mask && item.mask === '+9 (999) 999-99-99'
                    ? '+7 (999) 999-99-99'
                    : item.mask || '',"tooltip":_vm.needPhoneValidate && item.type === 'tel' ? _vm.$t('sure.tooltip') : null,"maxlength":item.maxlength},on:{"change":function($event){return _vm.onChange(key, $event.target.value)}},scopedSlots:_vm._u([(_vm.needPhoneValidate && item.type === 'tel')?{key:"icon",fn:function(){return [_c('plain-button',{attrs:{"icon":"error","tabindex":"-1","color":"del","size":"medium"}})]},proxy:true}:null,(_vm.needPhoneValidate && item.type === 'tel')?{key:"after",fn:function(){return [_c('plain-button',{attrs:{"tooltip":_vm.$t('sure.tooltip'),"color":"del"},on:{"click":function($event){return _vm.sendPhone(_vm.formData.phone, _vm.code)}}},[_vm._v(" "+_vm._s(_vm.$t('sure.send'))+" ")])]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.$t(`form.${key}.label`))+" ")])],1)}),(_vm.timezones.length)?_c('div',{staticClass:"l-col"},[_c('base-select',{attrs:{"name":"timezone","options":_vm.timezones,"value":_vm.timezone,"label":"v","track-by":"k","show-labels":false,"placeholder":_vm.$t('form.timezone.placeholder')},on:{"select":function($event){return _vm.onChange('timezone', $event.k)}}},[_vm._v(" "+_vm._s(_vm.$t('form.timezone.label'))+" ")])],1):_vm._e()],2)]),(_vm.provider === '2' || _vm.provider === '3')?_c('base-button',{staticClass:"tariff-plan__btn",attrs:{"slot":"footerEnd","theme":"outlined","loading":_vm.formLoading,"to":{ name: 'ReRegistration' }},slot:"footerEnd"},[_vm._v(" "+_vm._s(_vm.$t('form.btn.reRegistration'))+" ")]):_vm._e(),_c('base-button',{staticClass:"personal-submit__btn",attrs:{"slot":"footerEnd","disabled":_vm.isValid,"loading":_vm.formLoading},on:{"click":_vm.submit},slot:"footerEnd"},[_vm._v(" "+_vm._s(_vm.$t('form.btn.save'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }