var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start-page page"},[(['4', '5'].includes(_vm.provider))?_c('h1',{staticClass:"page-title start-page__alarm"},[_c('p',{staticClass:"large-title error-color",domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e(),_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.greeting)+" ")]),_c('div',{staticClass:"start-page__content"},[_c('page-block',{attrs:{"title":_vm.summaryTitles}},[_c('div',{staticClass:"l-flex"},[_c('div',{staticClass:"l-col-12_sm-12_md-12 l-flex-12"},_vm._l((_vm.grid),function(item){return _c('div',{key:item.key,staticClass:"l-col-12_sm-4_md-3_xl-2_lg-2__xs-12"},[_c('start-card',{attrs:{"number":_vm.services[item.key],"text":_vm.$tc(
                  _vm.provider === '6' && item.key === 'stack'
                    ? 'summary.services.astra'
                    : _vm.provider === '6' && item.key === 'dedic'
                    ? `summary.services.${item.key}`
                    : `summary.services.${item.key}`,
                  _vm.services[item.key]
                ),"color":_vm.getCardColor(item, _vm.services[item.key]),"to":item.to || null}})],1)}),0)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }