<template>
  <div class="checkbox-block">
    <base-checkbox
      v-tooltip="
        config.restrictclientchange && config.restrictclientchange === 'off'
          ? null
          : config.disabled
          ? tooltipSettings
          : null
      "
      :value="internalValue"
      :disabled="(!filter && label.includes('root')) || config.disabled"
      :true-value="config.trueValue"
      :false-value="config.falseValue"
      :hint="hint"
      :offer="isOffer"
      class="checkbox-block__checkbox"
      @change="onChange"
    >
      <!--      :value="filter && label.includes('root') ? 'off' : internalValue"-->
      <span>
        {{ isOffer ? modifyLabel : label }}
      </span>
      <span v-if="isOffer">
        <plain-button @click="toOffer" color="primary"> оферты </plain-button>
      </span>

      <span v-if="config.required" class="checkbox-block__asterisk error-color">*</span>
      <div v-if="config.link" class="checkbox-block__link">
        <plain-button :href="config.link.href" color="primary">
          {{ config.link.title }}
        </plain-button>
      </div>
    </base-checkbox>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import CheckboxComponent from '@/models/base/components/checkbox';
import { bmRusonyx } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'CheckboxBlock',
  components: { BaseCheckbox },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof CheckboxComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: null,
    },
    cost: {
      type: Number,
      default: 0,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => [1, 3, 6, 12].includes(parseInt(val)),
    },
    periodKey: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.config.value,
    };
  },
  computed: {
    tooltipSettings() {
      return {
        content: this.$t('restrictclientchange.on'),
        container: 'body',
        placement: 'top-start',
      };
    },
    isOffer() {
      return this.config?.desc?.includes('func=offer.print');
    },
    modifyLabel() {
      let lastIndex = this.label.lastIndexOf(' ');

      return this.label.substring(0, lastIndex);
    },
    hardCodeOffer() {
      if (this.config?.desc) {
        return {
          link: 'www.rusonyx.ru/support/dokumenty/dogovor/',
          title: 'публичная оферта',
        };
        // return this.config.desc.replace('/billmgr',`https://${bmRusonyx}/billmgr`);
        return this.config.desc.replace('/billmgr', `${this.$store.state.moduleApp.host}/billmgr`);
      } else return null;
    },
  },
  watch: {
    // config: function (newconfig) { из-за этого не срабатывает первый клик в ВПСордере
    //   console.log(newconfig);
    //   if (this.internalValue != newconfig.value) this.internalValue = newconfig.value;
    // },
    'config.value': function (newValue) {
      // console.log(newValue, this.internalValue);
      if (this.internalValue !== newValue) this.internalValue = newValue;
    },
    filter(event) {
      // console.log(event);
      if (event === false && this.internalValue === 'off') {
        this.internalValue = 'on';
        this.$emit('change', {
          value: 'on',
          sum: this.cost,
        });
      } else if (event && this.internalValue === 'on') {
        this.internalValue = 'off';
        this.$emit('change', {
          value: 'off',
          sum: this.cost,
        });
      }
    },
    internalValue(event) {
      // console.log(event);
      // if (event === false && this.internalValue === 'off') {
      //   this.internalValue = 'on';
      //   this.$emit('change', {
      //     value: this.filter && this.label.includes('root') ? 'off' : this.config.value,
      //     sum: this.cost,
      //   });
      // }
    },
  },
  methods: {
    toOffer() {
      window.open('https://www.rusonyx.ru/support/dokumenty/dogovor/', '_blanc');
    },
    onChange(value) {
      this.config.value = value;
      this.internalValue = this.config.value;
      this.$emit('change', {
        value: this.config.value,
        // value: this.filter && this.label.includes('root') ? 'off' : this.config.value,
        sum: this.cost,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.checkbox-block {
  &__checkbox {
    max-width: max-content;
  }

  &__link {
    margin-top: 0.25rem;
  }
}
</style>
