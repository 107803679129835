import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store';
import i18n from './i18n';
import VueMeta from 'vue-meta';
// import VueGtm from '@gtm-support/vue2-gtm';

// highlight
import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);
// global ui-libs
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// global styles
import '@/assets/styles/index.styl';

//global components
import '@/components/globalComponents';

import PrimeVue from 'primevue/config';

// import 'primevue/resources/themes/lara-light-teal/theme.css';
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';

// console.log(localStorage.getItem('vuex'));
// console.log(document.body.getAttribute('data-theme'));
// import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
// Vue.directive('tooltip', Tooltip);
Vue.use(PrimeVue, { ripple: true });

//global filters
import { separator } from './utils/separator';
Vue.filter('withSpace', separator);

Vue.config.productionTip = false;
Vue.use(VueMeta);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
